import React from "react";
import "../style.css";
import Icon1 from "../../assets/icon/icon1.svg";
import Icon2 from "../../assets/icon/icon2.svg";

function RedBar() {
  return (
    <div id="red-bar" className="d-flex align-items-center">
      <div className="row w-100 g-3">
        <div className="col-12 col-md-6 box d-flex align-items-center">
          <img src={Icon1} className="me-3" alt="" />
          <p className="mb-0">
            Feature vector extraction up to ~100 faces per second on single CPU
            core, and up 600 faces per second on GPU
          </p>
        </div>
        <div className="col-12 col-md-6 box d-flex align-items-center">
          <img src={Icon2} className="me-3" alt="" />
          <p className="mb-0">
            Our SDK can make 1:N matching within a database of 60 million
            records, achieving results in about a second, all with a single CPU
            core!
          </p>
        </div>
      </div>
    </div>
  );
}

export default RedBar;
