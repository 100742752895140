import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Icon3 from "../../assets/icon/icon3.svg";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 4 },
};

const items = [
  <div className="d-flex align-items-center flex-column p-4" data-value="1">
    <img src={Icon3} alt="" />
    <p className="mt-4">Identify faces and bodies in both images and videos</p>
  </div>,
  <div className="d-flex align-items-center flex-column p-4" data-value="1">
    <img src={Icon3} alt="" />
    <p className="mt-4">
      Authenticate and recognize up to 800 million faces per second with a false
      acceptance rate (FAR) of 1-in-125 million and a false rejection rate (FRR)
      of less than 1%.
    </p>
  </div>,
  <div className="d-flex align-items-center flex-column p-4" data-value="1">
    <img src={Icon3} alt="" />
    <p className="mt-4">Identify faces and bodies in both images and videos</p>
  </div>,
  <div className="d-flex align-items-center flex-column p-4" data-value="1">
    <img src={Icon3} alt="" />
    <p className="mt-4">
      Authenticate and recognize up to 800 million faces per second with a false
      acceptance rate (FAR) of 1-in-125 million and a false rejection rate (FRR)
      of less than 1%.
    </p>
  </div>,
  <div className="d-flex align-items-center flex-column p-4" data-value="1">
    <img src={Icon3} alt="" />
    <p className="mt-4">Identify faces and bodies in both images and videos</p>
  </div>,
  <div className="d-flex align-items-center flex-column p-4" data-value="1">
    <img src={Icon3} alt="" />
    <p className="mt-4">
      Authenticate and recognize up to 800 million faces per second with a false
      acceptance rate (FAR) of 1-in-125 million and a false rejection rate (FRR)
      of less than 1%.
    </p>
  </div>,
];

function Capability() {
  return (
    <div id="capability">
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
      />
    </div>
  );
}

export default Capability;

// import React from "react";
// import {
//   Carousel,
//   CarouselItem,
//   CarouselControl,
//   CarouselIndicators,
//   CarouselCaption,
// } from "reactstrap";
// import Icon from "../../assets/icon/icon.svg";
// class Capability extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       activeIndex: 0,
//       items: [
//         {
//           id: 1,
//           icon: Icon,
//           altText: "Slide 1",
//           caption: "Slide 1",
//         },
//         {
//           id: 2,
//           icon: Icon,
//           altText: "Slide 2",
//           caption: "Slide 2",
//         },
//         {
//           id: 3,
//           icon: Icon,
//           altText: "Slide 3",
//           caption: "Slide 3",
//         },
//         {
//           id: 4,
//           icon: Icon,
//           altText: "Slide 4",
//           caption: "Slide 4",
//         },
//         {
//           id: 5,
//           icon: Icon,
//           altText: "Slide 5",
//           caption: "Slide 5",
//         },
//         {
//           id: 6,
//           icon: Icon,
//           altText: "Slide 6",
//           caption: "Slide 6",
//         },
//       ],
//     };
//   }

//   next = () => {
//     const nextIndex =
//       this.state.activeIndex === this.state.items.length - 1
//         ? 0
//         : this.state.activeIndex + 1;
//     this.setState({ activeIndex: nextIndex });
//   };

//   previous = () => {
//     const nextIndex =
//       this.state.activeIndex === 0
//         ? this.state.items.length - 1
//         : this.state.activeIndex - 1;
//     this.setState({ activeIndex: nextIndex });
//   };

//   goToIndex = (newIndex) => {
//     this.setState({ activeIndex: newIndex });
//   };

//   render() {
//     const { activeIndex } = this.state;

//     const slides = this.state.items.map((item) => (
//       <CarouselItem
//         onExiting={this.onExiting}
//         onExited={this.onExited}
//         key={item.id}
//       >
//         <img src={item.icon} alt={item.altText} />
//         <CarouselCaption
//           captionText={item.caption}
//           captionHeader={item.caption}
//         />
//       </CarouselItem>
//     ));

//     return (
//       <div id="capability">
//         <Carousel
//           activeIndex={activeIndex}
//           next={this.next}
//           previous={this.previous}
//           interval={false}
//         >
//           <CarouselIndicators
//             items={this.state.items}
//             activeIndex={activeIndex}
//             onClickHandler={this.goToIndex}
//           />
//           {slides}
//           <CarouselControl
//             direction="prev"
//             directionText="Previous"
//             onClickHandler={this.previous}
//           />
//           <CarouselControl
//             direction="next"
//             directionText="Next"
//             onClickHandler={this.next}
//           />
//         </Carousel>
//       </div>
//     );
//   }
// }

// export default Capability;
