import React from "react";
import "../style.css";

function Table() {
  return (
    <div id="table">
      <table className="table">
        <tbody>
          <tr>
            <td>Title : </td>
            <td>PAPIL11 s.r.o. </td>
          </tr>
          <tr>
            <td>Address : </td>
            <td>Rumunská 26 Praha 2 120 00</td>
          </tr>
          <tr>
            <td>e-Mail : </td>
            <td>info@papil11.com</td>
          </tr>
          <tr>
            <td>Website : </td>
            <td>www.papil11.cz</td>
          </tr>
          <tr>
            <td>Tax Number : </td>
            <td>109 75 675</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Table;
