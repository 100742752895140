import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Pages/Main";
import Company from "./Pages/Company";
import "./assets/css/style.css";

function App() {
  return (
    <Router>
      <Routes>
        {/* Ana sayfa yüklenirken BeogsMain'e yönlendirme (redirect) */}
        <Route path="/" element={<Main />} />

        {/* Diğer rotalar */}
        <Route path="/company" element={<Company />} />
      </Routes>
    </Router>
  );
}

export default App;
