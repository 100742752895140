import React from "react";
import Topbar from "../Components/Topbar/Topbar";
import Footer from "../Components/Footer/Footer";
import Table from "../Components/Table/Table";

function Main() {
  return (
    <div>
      <Topbar />
      <Table />
      <div className="p-absolute b-0 w-100">
        <Footer />
      </div>
    </div>
  );
}

export default Main;
