import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../../assets/Papil11-logo.svg";
import "../style.css";

function Topbar() {
  return (
    <div id="topbar">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <RouterLink to="/">
          <img src={Logo} className="logo" alt="Papil11 Logo" />
        </RouterLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="myScrollTarget"
                spy={true}
                smooth={true}
                duration={0}
              >
                Ask an Expert
              </ScrollLink>
            </li>
            <li className="nav-item">
              <RouterLink className="nav-link" to="/Company">
                Company
              </RouterLink>
            </li>
            <li className="nav-item">
              <ScrollLink
                className="nav-link"
                to="myScrollTarget"
                spy={true}
                smooth={true}
                duration={0}
              >
                Contact Us
              </ScrollLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Topbar;
