import React from "react";
import "../style.css";
import TurkMali from "../../assets/icon/turkmali.svg";
import CE from "../../assets/icon/ce.svg";
import ISO from "../../assets/icon/iso.svg";
import TSE from "../../assets/icon/tse.svg";
import BDDK from "../../assets/icon/bddk.svg";

function Certificate() {
  return (
    <div id="certificate">
      <h3 className="mb-3">Certificate</h3>
      <div className="d-flex align-items-center justify-content-around background">
        <img src={TurkMali} alt="" />
        <img src={CE} alt="" />
        <img src={ISO} alt="" />
        <img src={TSE} alt="" />
        <img src={BDDK} alt="" />
        <img src={TurkMali} alt="" />
      </div>
    </div>
  );
}

export default Certificate;
