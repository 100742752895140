import React from "react";
import "../style.css";
import FooterLogo from "../../assets/Papil11-footer-logo.svg";

function Footer() {
  return (
    <div id="footer">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <img className="footer-logo mb-3" src={FooterLogo} alt="" />
          <p>Papilon Defence Technology and Trade Joint Stock Company</p>
        </div>
        <div>
          <div className="addMail">
            <div className="input-group mb-3">
              <input
                type="emil"
                className="form-control"
                placeholder="E-mail"
                aria-label="E-mail"
                aria-describedby="button-addon"
              />
              <button
                className="btn btn-outline-secondary"
                type="submit"
                id="button-addon"
              >
                Subscribe
              </button>
            </div>
            <p>Subscribe to our newsletter to learn about more!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
