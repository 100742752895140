import React from "react";
import "../style.css";
import UseCaseImage from "./UseCaseImage";
import Security from "../../assets/images/security.jpg";
import Banking from "../../assets/images/banking.jpg";
import Law from "../../assets/images/law.jpg";
import Marketing from "../../assets/images/marketing.jpg";
import Healthcare from "../../assets/images/healthcare.jpg";
import Education from "../../assets/images/education.jpg";

// Özelleştirilmiş UseCaseItem bileşeni
function UseCaseItem({ title, description, image, alt }) {
  return (
    <div className="col-12 col-md-4 card px-0">
      <UseCaseImage image={image} alt={alt} />
      <div className="p-3">
        <h5 className="mb-2">{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
}

function UseCase() {
  const useCases = [
    {
      image: Security,
      alt: "Security Image",
      title: "Security and Access Control",
      description:
        "Face authentication, person of interest (POI) detection, employee access control, customer authentication, border security (passport control and other border crossing procedures).",
    },
    {
      image: Banking,
      alt: "Banking Image",
      title: "Banking and Finance",
      description:
        "Use facial recognition for: biometric authentication, fraud detection and prevention, customer onboarding and KYC, ATM and payment security, personalized banking experience, etc.",
    },
    {
      image: Law,
      alt: "Law Image",
      title: "Law Enforcement",
      description:
        "Identify suspects, victims, and witnesses: surveillance and monitoring, crowd management and control.",
    },
    {
      image: Marketing,
      alt: "Marketing Image",
      title: "Marketing and Advertising",
      description:
        "Targeted advertising, customer analytics, product placement and market research.",
    },
    {
      image: Healthcare,
      alt: "Healthcare Image",
      title: "Healthcare",
      description:
        "Identify patients, track their progress, and provide personalized care.",
    },
    {
      image: Education,
      alt: "Education Image",
      title: "Education",
      description:
        "Identify students, track their attendance, and provide personalized instruction.",
    },
  ];

  return (
    <div id="use-case">
      <h3 className="mb-5">Papilon Face SDK/API Use Cases</h3>
      <div className="row g-3">
        {useCases.map((useCase, index) => (
          <UseCaseItem key={index} {...useCase} />
        ))}
      </div>
    </div>
  );
}

export default UseCase;
