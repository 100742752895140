import React from "react";
import { Link } from "react-router-dom";
import "../style.css";
import FaceRec from "../../assets/images/faceRec.png";

function Enterance() {
  return (
    <div id="enterance" className="row d-flex align-items-center">
      <div className="col-12 col-md-6">
        <img src={FaceRec} className="col-12" alt="" />
        <div className="rectangle">
          <div className="corner-line vertical top-left"></div>
          <div className="corner-line vertical top-right"></div>
          <div className="corner-line vertical bottom-left"></div>
          <div className="corner-line vertical bottom-right"></div>
          <div className="corner-line horizontal top-left"></div>
          <div className="corner-line horizontal top-right"></div>
          <div className="corner-line horizontal bottom-left"></div>
          <div className="corner-line horizontal bottom-right"></div>
          <div className="tracking-rectangle"></div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <h1 className="mb-4">Face Recognition SDK/API</h1>
        <h3 className="mb-3">
          TOP NIST FRVT face recognition SDK for server, mobile & embedded
          solutions with passive and active liveness detection, detailed
          documentation and expert tech support
        </h3>
        <p>
          Papilon Face SDK/API is a comprehensive set of software components
          aimed at developing face recognition solutions. It offers a broad
          spectrum of features including face detection, face tracking and
          verification, attribute estimation like gender and age, image quality
          assessment, liveness estimation, and supports various programming
          interfaces for development across different platforms.
        </p>
        <Link className="button" to="#">
          Get Trial
        </Link>
      </div>
    </div>
  );
}

export default Enterance;
